import React from "react";
import Icon from "../UI/Icon";

const CheckIcon = () => {
  return (
    <Icon viewBox="0 0 24 24">
      <path d="M8.9999 16.2001L4.7999 12.0001L3.3999 13.4001L8.9999 19.0001L20.9999 7.0001L19.5999 5.6001L8.9999 16.2001Z" />
    </Icon>
  );
};

export default CheckIcon;
